/* eslint-disable import/first */
require('dotenv').config();

import React, { Suspense, Component } from 'react';
import moment from 'moment';
// add duration format to moment.js for simplicity
import momentDurationFormatSetup from 'moment-duration-format';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  CssBaseline,
  Button,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Box
} from '@mui/material';
import './Middleware/Api';
import ToastMessage from './Components/Widgets/ToastMessage';
// import Routes, { renderRoutes } from './Routes';
import LoadingComponent from './Components/Widgets/LoadingComponent';
import { ClearUserOnPageLoad } from './Lib/ClearUserOnPageLoad';
import { ScrollToTop } from './Lib/ScrollToTop';
import './App.scss';
import AuthenticationStore from './Stores/Authentication';
import Banner from './Components/Shared/Banner';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster, toast } from 'react-hot-toast';
import DataStore from './Stores/DataStore';
import { getDeviceInfo, getExtensionInfo } from './Lib/extension';
import NucleosRoutes from './NucleosRoutes';
import { GRAY, LINK_BLUE, WHITE } from './constants/colors';
import Data from './Middleware/Data';
import AchieveDxpLogo from './Assets/achieve-dxp-logo.svg';
import AchieveDxpLogoMonochromeWhite from './Assets/achieve-dxp-logo-monochrome-white.svg';
import { AppContextProvider } from './Contexts/AppContext';
import ExtensionPrompt from './Components/ExtensionPrompt';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false
    }
  }
});

// const Tracker = () => {
//   const location = useLocation()
//   useEffect(() => {
//     if (!window.gtag) return
//     window.gtag('event', 'pageview', {
//       page_path: location.pathname,
//       page_location: window.location.href
//     })
//   }, [location])
//   return <div />
// }

momentDurationFormatSetup(moment);

const setTabTitle = (title) => {
  document.title = title;
};

const setCSSVariables = (theme) => {
  const styles = {
    '--primary-main': theme.palette.primary.main,
    '--primary-light': theme.palette.primary.light,
    '--primary-dark': theme.palette.primary.dark,
    '--primary-contrastText': theme.palette.primary.contrastText,

    '--error-main': theme.palette.error.main,
    '--error-light': theme.palette.error.light,
    '--error-dark': theme.palette.error.dark,
    '--error-contrastText': theme.palette.error.contrastText,

    '--warning-main': theme.palette.warning.main,
    '--warning-light': theme.palette.warning.light,
    '--warning-dark': theme.palette.warning.dark,
    '--warning-contrastText': theme.palette.warning.contrastText,

    '--success-main': theme.palette.success.main,
    '--success-light': theme.palette.success.light,
    '--success-dark': theme.palette.success.dark,
    '--success-contrastText': theme.palette.success.contrastText
  };

  const documentRoot = document.querySelector(':root');
  Object.keys(styles).map((styleVariable) => documentRoot.style.setProperty(styleVariable, styles[styleVariable]));
};

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#009791'
    },
    secondary: {
      main: '#377CFD'
    },
    white: {
      main: WHITE
    },
    disabled: {
      main: GRAY
    },
    link: {
      main: LINK_BLUE
    }
  },
  typography: {
    fontFamily: [
      'Nunito',
      'sans-serif'
    ].join(', '),
    disabled: {
      color: GRAY
    },
    link: {
      color: LINK_BLUE
    },
    white: {
      color: WHITE
    }
  },
  components: {
    MuiLink: {
      variants: [
        {
          props: { variant: 'link' },
          style: {
            color: LINK_BLUE
          }
        },
        {
          props: { variant: 'disabled' },
          style: {
            color: GRAY
          }
        }
      ]
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 72
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    }
  }
});

@observer
class App extends Component {
  constructor (props) {
    super(props);

    this.state = {
      appConfigData: {
        configLoaded: false,
        logoURL: AchieveDxpLogo,
        monochromeLogoURL: AchieveDxpLogoMonochromeWhite
      },
      theme: defaultTheme,

      showError: true,
      showWarningBanner:
        !localStorage.getItem('warningBannerClosed') &&
        window.nucleosConfig.warningBannerMessage,
      showInfoBanner:
        !localStorage.getItem('infoBannerClosed') &&
        window.nucleosConfig.infoBannerMessage
    };
  }

  async componentDidMount () {
    const { theme } = this.state;

    document.cookie = `host=${window.location.hostname};path=/;domain=${window.location.hostname}`;
    const error = localStorage.getItem('error');
    // if there is error, we open toast message.
    if (error == 'true') {
      this.setState({ showError: true });
    } else {
      this.setState({ showError: false });
    }
    localStorage.removeItem('error');

    async function detectExtension () {
      try {
        getExtensionInfo();
        getDeviceInfo();
      } catch {
        // NOP
      }

      // for 1 sec, try to detect extension because there is a delay of extension deploy
      for (let i = 0; i < 10; i++) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (document.querySelector('#appbar-root')) {
          const exisingData = (window && window.nucleosExtension) || {};
          window.nucleosExtension = { ...exisingData, app_bar_detected: true };
          break;
        }
      }
      AuthenticationStore.setExtensionInfo(window.nucleosExtension);
    }
    detectExtension();

    const clientThemePreferences = await Data.getClientTheme();

    const updatedTheme = createTheme(theme, {
      palette: {
        primary: theme.palette.augmentColor({
          color: {
            main: (clientThemePreferences && clientThemePreferences.settings && clientThemePreferences.settings.primaryColor) || theme.palette.primary.main
          }
        }),
        secondary: theme.palette.augmentColor({
          color: {
            main: (clientThemePreferences && clientThemePreferences.settings && clientThemePreferences.settings.secondaryColor) || theme.palette.secondary.main
          }
        }),
        white: theme.palette.augmentColor({
          color: {
            main: WHITE
          },
          name: 'white'
        }),
        disabled: theme.palette.augmentColor({
          color: {
            main: GRAY
          },
          name: 'disabled'
        }),
        link: theme.palette.augmentColor({
          color: {
            main: LINK_BLUE
          },
          name: 'link'
        })
      }
    });

    this.setState({
      theme: updatedTheme,
      appConfigData: {
        ...this.state.appConfigData,
        logoURL: (clientThemePreferences && clientThemePreferences.settings && clientThemePreferences.settings.logoURL) || AchieveDxpLogo,
        monochromeLogoURL: (clientThemePreferences && clientThemePreferences.settings && clientThemePreferences.settings.monochromeLogoURL) || AchieveDxpLogoMonochromeWhite,
        configLoaded: true
      }
    });

    setCSSVariables(updatedTheme);

    if (clientThemePreferences && clientThemePreferences.settings && clientThemePreferences.settings.appName) {
      setTabTitle(clientThemePreferences.settings.appName);
    }
  }

  handleClose = () => {
    this.setState({ showError: false });
  };

  handle401MessageButtonClick = () => {
    const { host } = window.location;
    window.location.assign(`://${host}/login`);
  };

  handlePermissionRefresh = () => {
    DataStore.setPermissionChangeToast(false);
    // TODO : Uncomment if this api is required on permission refresh
    // queryClient.invalidateQueries({ queryKey: ['GRIEVANCE_PERMISSIONS'] })
    toast.dismiss();
    if (DataStore.isCurrentUserFormsAdmin()) {
      this.props.history.push('/forms/admin');
    } else {
      this.props.history.push('/home');
    }
    window.location.reload();
  }

  getPermissionChangeToast = () => {
    if (DataStore.showPermissionChangeToast) {
      toast.success(<div className="nucleos-core"> Your permissions has been updated, click <span className="cursor-pointer underline" style={{ color: '#449fff' }} onClick={this.handlePermissionRefresh}>here</span>  to refresh the permissions </div>,
        {
          duration: Infinity
        }
      );
    }
  }

  render () {
    const { showError, showWarningBanner, showInfoBanner, theme, appConfigData } = this.state;
    const warningBannerMessage = window.nucleosConfig.warningBannerMessage || '';
    const infoBannerMessage = window.nucleosConfig.infoBannerMessage || '';

    let warningBannerHeight = 0;
    if (showWarningBanner && document.getElementById('warning-banner') && document.getElementById('warning-banner').offsetHeight) {
      warningBannerHeight = document.getElementById('warning-banner').offsetHeight;
    }

    this.getPermissionChangeToast();

    if (!appConfigData.configLoaded) {
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Box display='flex' justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
              <LoadingComponent />
            </Box>
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }

    return (
      <QueryClientProvider client={queryClient}>
        <AppContextProvider value={appConfigData}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className="banner-container">
                  <Banner
                    type="warning"
                    bannerId='warning-banner'
                    show={showWarningBanner && AuthenticationStore.isAuthenticated && AuthenticationStore.userRole === 'learner'}
                    message={warningBannerMessage}
                    handleClose={() => {
                      localStorage.setItem('warningBannerClosed', true);
                      this.setState({ showWarningBanner: false });
                    }}
                  />

                  <Banner
                    type="info"
                    bannerId='info-banner'
                    top={warningBannerHeight}
                    show={showInfoBanner && AuthenticationStore.isAuthenticated && AuthenticationStore.userRole === 'learner'}
                    message={infoBannerMessage}
                    handleClose={() => {
                      localStorage.setItem('infoBannerClosed', true);
                      this.setState({ showInfoBanner: false });
                    }}
                  />
                  {/* <RecordingIcon /> */}
                </div>

                <div style={{ display: 'flex', flex: 1, position: 'relative', overflow: 'hidden' }}>
                  <ScrollToTop />
                  {/* We don't want users carrying over from one
              page to another */}
                  <ClearUserOnPageLoad />
                  <Toaster position="bottom-center" />
                  <CssBaseline />
                  {<ExtensionPrompt />}

                  <Box display='flex' flex={1} sx={{ maxWidth: '100%' }}>
                    <Suspense fallback={<LoadingComponent />}>
                      {/* <Switch>{renderRoutes(Routes)}</Switch> */}
                      <NucleosRoutes />
                    </Suspense>
                  </Box>

                  <ToastMessage
                    autoHideDuration={10000}
                    message="You’ve been logged out. Please log-in again"
                    onClose={this.handleClose}
                    open={showError}
                    type="info"
                    action={
                      <Button
                        color="primary"
                        size="small"
                        onClick={this.handle401MessageButtonClick}
                      >
                    Log in
                      </Button>
                    }
                  />
                </div>
              </div>
            </ThemeProvider>
          </StyledEngineProvider>
        </AppContextProvider>
      </QueryClientProvider>
    );
  }
}

export default withRouter(App);
