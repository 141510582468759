import { ACTIVE_ROLES } from '../Lib/CONSTANTS';
import { useGrievanceRole } from './useGrievancePermissions';
import { useUserQuery } from './useUser';

export default function useUserPermissions () {
  const userQuery = useUserQuery();

  const grievancePermissions = useGrievanceRole();

  const loggedInUser = userQuery.data;

  return {
    get isLoading () {
      return userQuery.isLoading || grievancePermissions.isLoading;
    },

    get role () {
      if (this.isLoading) { return null; }
      return loggedInUser.Role.name;
    },

    canEnableDisableApp () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canAddRemoveUserInApp () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canCreateUser () {
      if (this.isLoading) { return null; }
      const allowAddUsers = window.nucleosConfig.allowAddUsers;
      return (
        allowAddUsers &&
        [ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name)
      );
    },

    canSelectUsersForBulkActions (listViewMode) {
      if (this.isLoading) { return null; }

      if ([ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name)) {
        return true;
      }

      return (
        [ACTIVE_ROLES.STAFF_ADMIN].includes(loggedInUser.Role.name) &&
        ['LEARNER', 'NON_LEARNER'].includes(listViewMode)
      );
    },

    canActivateOtherUser () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name);
    },

    canDeactivateOtherUser () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name);
    },

    canSetDefaultPassword () {
      if (this.isLoading) { return null; }

      if (!window.nucleosConfig.setDefaultPasswordEnabled) {
        return false;
      }

      return [ACTIVE_ROLES.SUPER_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canAddUserToAGroup () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canRemoveUserFromAGroup () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canSetGoalsForLearner () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canViewLearningRecords () {
      if (this.isLoading) { return null; }
      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.LEARNER
      ].includes(loggedInUser.Role.name);
    },

    canAssignGrievanceRoleToUser () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name);
    },

    canCreateGrievanceTemplate () {
      if (this.isLoading) { return null; }
      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.EXTERNAL_PARTNER
      ].includes(loggedInUser.Role.name);
    },

    canDownloadGrievanceAttachment () {
      if (this.isLoading) { return null; }
      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.EXTERNAL_PARTNER
      ].includes(loggedInUser.Role.name);
    },

    canDeleteGrievanceAttachment (creatorId) {
      if (this.isLoading) { return null; }

      if (creatorId !== loggedInUser.uid) {
        return false;
      }

      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.EXTERNAL_PARTNER
      ].includes(loggedInUser.Role.name);
    },

    canViewGrievanceAttachmentList () {
      if (this.isLoading) { return null; }
      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.EXTERNAL_PARTNER
      ].includes(loggedInUser.Role.name);
    },

    canViewGrievanceReport () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canViewGroupLearningRecords () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canSetGrievanceLimit () {
      if (this.isLoading) { return null; }
      const grievanceAllowed = window.nucleosConfig.formsEnabled;

      return (
        grievanceAllowed &&
        [ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name)
      );
    },

    canSetApplicationAccess () {
      if (this.isLoading) { return null; }

      return [ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name);
    },

    canViewFaceCaptureLogs () {
      if (this.isLoading) { return null; }

      const faceCaptureAllowed = window.nucleosConfig.captureFaceEnabled;
      return faceCaptureAllowed && [ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name);
    },

    canViewAuditLogs () {
      if (this.isLoading) { return null; }

      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(loggedInUser.Role.name);
    },

    canViewMessenger () {
      if (this.isLoading) { return null; }
      if (window.nucleosConfig.messengerEnabled !== true) { return false; }

      if (loggedInUser.Role.name === ACTIVE_ROLES.LEARNER) {
        if (window.nucleosConfig.disableConversationView === true) { return false; }
      }

      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.INVESTIGATOR,
        ACTIVE_ROLES.MESSENGER,
        ACTIVE_ROLES.LEARNER
      ].includes(loggedInUser.Role.name);
    },

    canViewMessengerConversationView () {
      if (this.isLoading) { return null; }
      if (window.nucleosConfig.messengerEnabled !== true) { return false; }

      if (window.nucleosConfig.disableConversationView === true) { return false; }

      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.INVESTIGATOR,
        ACTIVE_ROLES.MESSENGER,
        ACTIVE_ROLES.LEARNER
      ].includes(loggedInUser.Role.name);
    },

    canUseMessengerAdminFeatures () {
      if (this.isLoading) { return null; }
      if (window.nucleosConfig.messengerEnabled !== true) { return false; }

      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.INVESTIGATOR
      ].includes(loggedInUser.Role.name);
    },

    canBanUnbanLearner () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN].includes(loggedInUser.Role.name);
    },

    canViewLearnerSessions () {
      if (this.isLoading) { return null; }
      if (!window.nucleosConfig.userDeviceLockDownEnabled) { return false; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(loggedInUser.Role.name);
    },

    canGoToLiveActivity () {
      if (this.isLoading) { return null; }
      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canCreateGroup () {
      if (this.isLoading) { return null; }

      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canDeleteGroup () {
      if (this.isLoading) { return null; }

      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canManageGroups () {
      if (this.isLoading) { return null; }

      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canEditGroup () {
      if (this.isLoading) { return null; }

      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canGoToGrievances () {
      if (this.isLoading) { return null; }

      return grievancePermissions.canGoToGrievances();
    },

    canViewAppAuditHistory () {
      if (this.isLoading) { return null; }

      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canEditAppAvailabilityAndLimits () {
      if (this.isLoading) { return null; }

      return [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
        loggedInUser.Role.name
      );
    },

    canViewAccountSettings () {
      if (this.isLoading) { return null; }

      return [
        ACTIVE_ROLES.SUPER_ADMIN,
        ACTIVE_ROLES.STAFF_ADMIN,
        ACTIVE_ROLES.EXTERNAL_PARTNER,
        ACTIVE_ROLES.FORMS_SUPER_ADMIN
      ].includes(loggedInUser.Role.name);
    }
  };
}
