import React, { useEffect } from 'react';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import { Box, Divider, Tab, Tabs } from '@mui/material';

import ActiveSessions from './ActiveSessions';
import AuditLogs from './AuditLogs';
import FlaggedEventsTable from './FlaggedActivity/FlaggedEventsTable';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import { LEARNER_ACTIVE_SESSION_PATH } from '../../constants/paths';

const TABS = {
  ActiveSessions: 'active-sessions',
  AuditLogs: 'audit-logs',
  FlaggedEvents: 'flagged-events'
};

function LearnerSessionLookup () {
  const history = useHistory();
  const { path, params } = useRouteMatch();
  const { tabId } = params;

  function handleTabChange (_, value) {
    history.replace(generatePath(path, {
      tabId: value
    }));
  }

  useEffect(() => {
    if (!tabId) {
      history.replace(LEARNER_ACTIVE_SESSION_PATH);
    }
  }, [history, tabId]);

  return (
    <Box>
      <HeaderSubPanel sx={{ mb: 3 }} title="Device Lock" />
      <div style={{ paddingBottom: '50px' }}>
        <Tabs value={tabId} onChange={handleTabChange}>
          <Tab
            style={{ fontSize: 14 }}
            label="ACTIVE SESSIONS"
            value={TABS.ActiveSessions}
          />
          <Tab
            style={{ fontSize: 14 }}
            label="AUDIT LOGS"
            value={TABS.AuditLogs}
          />
          <Tab
            style={{ fontSize: 14 }}
            label="FLAGGED EVENTS"
            value={TABS.FlaggedEvents}
          />
        </Tabs>

        <Divider />

        {tabId === TABS.ActiveSessions && <ActiveSessions />}
        {tabId === TABS.AuditLogs && <AuditLogs />}
        {tabId === TABS.FlaggedEvents && <FlaggedEventsTable />}
      </div>
    </Box>
  );
}

export default LearnerSessionLookup;
