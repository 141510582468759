import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Box, Divider } from '@mui/material';
import BannedUserIcon from '../../../Assets/banned_user_icon.svg';
import FlaggedUserIcon from '../../../Assets/flagged_user_icon.svg';
import DeviceFlaggedUserIcon from '../../../Assets/device_flagged_user_icon.svg';
import { Button, DateRangePicker, MultiSelectDropDown, TimeLine, Typography } from '@nucleos/core-ui';
import { usePaginatedQuery } from '../../../hooks/usePaginatedQuery';
import Data from '../../../Middleware/Data';
import ScrollBottomLoading from '../../../Components/Shared/ScrollBottomLoading';
import { QueryKeys } from '../../../Lib/query-keys';
import { useFiltersManager } from '../../../hooks/useFilters';
import { LoadingAndErrorHandler } from '../../../Components/Shared/LoadingErrorHandler';
import { getDays, getTimePeriodFilterOptions } from '../../../Lib/util';
import BanUserModalContent from '../../../Components/User/BanUserModalContent';
import UnbanUserModalContent from '../../../Components/User/UnbanUserModalContent';
import { useMutation } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import toast from 'react-hot-toast';
import FaceCaptureModal from '../../Users/FaceCaptureLogs/FaceCaptureModal';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';
import { LEARNER_SESSION_PATH } from '../../../constants/paths';
import useUserPermissions from '../../../hooks/useUserPermissions';

const breadcrumbLinks = [
  { href: LEARNER_SESSION_PATH, label: 'Device Lock' },
  { href: '#', label: 'Activity Timeline' }
];

const ReadMoreText = ({ className, text, maxLength, typographyProps }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const showReadMore = useMemo(() => !!text.slice(maxLength).length, [text, maxLength]);

  return (
    <div className={className}>
      {isTruncated
        ? (
          <Typography {...typographyProps} style={{ display: 'inline' }}>
            {text.slice(0, maxLength)}

            {
              showReadMore
                ? (
                  <span className="ml-1" onClick={toggleTruncate} style={{ cursor: 'pointer', color: '#449FFF' }}>
                Read more...
                  </span>
                )
                : null
            }
          </Typography>
        )
        : (
          <Typography {...typographyProps} style={{ display: 'inline' }}>
            {text}

            {
              showReadMore
                ? (
                  <span className="ml-1" onClick={toggleTruncate} style={{ cursor: 'pointer', color: '#449FFF' }}>
                Read less
                  </span>
                )
                : null
            }
          </Typography>
        )}
    </div>
  );
};

const TimelineItem = ({ activity, userDetails, onFaceClick }) => {
  const icon = useMemo(() => {
    let icon = '';

    if (activity.sourceType === 'BAN') {
      icon = BannedUserIcon;
    } else if (activity.sourceType === 'FLAG') {
      icon = FlaggedUserIcon;
    } else if (activity.sourceType === 'POD_FLAG') {
      icon = DeviceFlaggedUserIcon;
    }

    return icon;
  }, [activity.sourceType]);

  return (
    <div className='flex gap-x-4 w-full rounded-lg bg-white p-4 mb-6' style={{ boxShadow: '0px 10px 40px rgba(64, 72, 82, 0.1)' }}>
      <div className='flex-none'>
        <img src={icon} />
      </div>

      <div className='flex flex-col flex-auto gap-4'>
        <div className='flex justify-between items-start'>
          <div className='flex flex-col'>
            <Typography className='font-bold' variant="h5">
              {
                `
                ${userDetails.firstName} ${userDetails.lastName}${activity.sourceType === 'BAN'
      ? ` has been banned ${activity.UserBan.banIndefinitely ? 'indefinitely' : `for next ${activity.UserBan.periodInDays} days.`}`
      : activity.sourceType === 'FLAG'
        ? '\'s login activity has been flagged.'
        : '\'s device activity has been flagged.'
    }
                `
              }
            </Typography>

            {
              activity.UserBan && activity.UserBan.admin
                ? (
                  <Typography variant="subtitle2">{`By: ${activity.UserBan.admin.firstName} ${activity.UserBan.admin.lastName}`}</Typography>
                )
                : null
            }
          </div>

          {
            activity.sourceType === 'BAN'
              ? (
                <div className='flex flex-col'>
                  <Typography variant="subtitle2">Banned Until</Typography>

                  <Typography className="font-semibold" variant="h6">
                    {
                      activity.UserBan.banIndefinitely
                        ? 'Indefinitely'
                        : moment(activity.UserBan.bannedUntil).format('MMM DD, YYYY')
                    }
                  </Typography>
                </div>
              )
              : null
          }
        </div>

        {
          activity.sourceType === 'BAN'
            ? (
              <div className='flex flex-col gap-1'>
                {
                  activity.UserBan.internalNote
                    ? (
                      <div className='flex gap-x-1'>
                        <Typography className='font-semibold' style={{ display: 'inline' }}>
                      Internal Note:
                          <ReadMoreText className="inline" text={activity.UserBan.internalNote} maxLength={150} />
                        </Typography>
                      </div>
                    )
                    : null
                }

                {
                  activity.UserBan.noteForLearner
                    ? (
                      <div className='flex gap-x-1'>
                        <Typography className='font-semibold' style={{ display: 'inline' }}>
                      Note for Learner:
                          <ReadMoreText className="inline" text={activity.UserBan.noteForLearner} maxLength={150} />
                        </Typography>
                      </div>
                    )
                    : null
                }

              </div>
            )
            : (
              activity.sourceType === 'FLAG'
                ? (
                  <div className='flex gap-x-10'>
                    <div className='flex flex-col gap-2'>
                      <Typography variant="subtitle2" className='w-40'>Face captured during flagged activity</Typography>

                      <img
                        className='rounded-md cursor-pointer w-48'
                        src={activity.UserFlag.currFace.signedUrl}
                        onClick={() => onFaceClick({
                          imageURL: activity.UserFlag.currFace.signedUrl
                        })}
                      />

                      <Typography variant="subtitle2" className='w-40'>{`Device Serial Number: ${activity.UserFlag.currFace.deviceSerialNumber}`}</Typography>
                    </div>

                    <div className='flex flex-col gap-2'>
                      <Typography variant="subtitle2" className='w-40'>Face captured in the last session</Typography>

                      <img
                        className='rounded-md cursor-pointer w-48'
                        src={activity.UserFlag.validFace.signedUrl}
                        onClick={() => onFaceClick({
                          imageURL: activity.UserFlag.validFace.signedUrl
                        })}
                      />

                      <Typography variant="subtitle2" className='w-40'>{`Device Serial Number: ${activity.UserFlag.validFace.deviceSerialNumber}`}</Typography>
                    </div>
                  </div>
                )
                : (
                  <div className='flex gap-x-10'>
                    {
                      activity.UserPodFlag.currFace
                        ? (
                          <div className='flex flex-col gap-2'>
                            <Typography variant="subtitle2" className='w-40'>Face captured</Typography>

                            <img
                              className='rounded-md cursor-pointer w-48'
                              src={activity.UserPodFlag.currFace.signedUrl}
                              onClick={() => onFaceClick({
                                imageURL: activity.UserPodFlag.currFace.signedUrl
                              })}
                            />
                          </div>
                        )
                        : null
                    }

                    <div className='flex flex-col gap-2'>
                      <Typography variant="subtitle2" className='w-40'>Device accessed from</Typography>

                      <Typography className='w-40'>{activity.UserPodFlag.actualPod}</Typography>
                    </div>

                    <div className='flex flex-col gap-2'>
                      <Typography variant="subtitle2" className='w-40'>Assigned POD</Typography>

                      <Typography className='w-40'>{activity.UserPodFlag.assignedPod}</Typography>
                    </div>
                  </div>
                )
            )
        }
      </div>
    </div>
  );
};

const UserActions = {
  BanUser: 'BanUser',
  UnbanUser: 'UnbanUser'
};

const FilterKeys = {
  DateRangeStart: 'DateRangeStart',
  DateRangeEnd: 'DateRangeEnd',
  Type: 'Type'
};

const STATUS_OPTIONS = [
  { title: 'All Events', value: '' },
  { title: 'Login Flags', value: 'FLAG' },
  { title: 'Device Flags', value: 'POD_FLAG' },
  { title: 'Banned', value: 'BAN' }
];

const FILTERED_TIME_OPTIONS = [
  ...getTimePeriodFilterOptions()
    .filter(item => !['last_seven_days'].includes(item.timePeriod))
    .map(item => {
      item.val = `${item.name}|${item.val}`;
      return item;
    }),
  ...getDays().filter(item => ['month'].includes(item.timePeriod))
];

const options = FILTERED_TIME_OPTIONS.map(({ name, val, timePeriod }) => {
  return { title: name, value: val };
});

const timePeriodOptions = [
  ...options,
  { value: '', title: 'Custom Date' }
];

// eslint-disable-next-line no-unused-vars
const [_timePeriodTitle, start, end] = timePeriodOptions[0].value.split('|');

const transformValue = (v) => ({ title: v.meta.label, value: v.value });

const FlaggedActivityTimeline = () => {
  const userPermissions = useUserPermissions();

  const params = useParams();
  const { uid } = params;

  console.log(uid, params);

  const [faceCaptureModalOpen, setFaceCaptureModalOpen] = useState(false);
  const [faceCaptureDetails, setFaceCapturedDetails] = useState({});

  const unbanLearnerMutation = useMutation((data) =>
    Data.unbanLearner(data).then(GenericErrorDetectorForMutations)
  );

  const banLearnerMutation = useMutation((data) =>
    Data.banLearner(data).then(GenericErrorDetectorForMutations)
  );

  const filterManager = useFiltersManager({
    defaultFilter: [{
      key: FilterKeys.DateRangeStart,
      value: moment(start).format('YYYY-MM-DD')
    }, {
      key: FilterKeys.DateRangeEnd,
      value: moment(end).format('YYYY-MM-DD')
    }, {
      key: FilterKeys.Type,
      value: STATUS_OPTIONS[0].value,
      meta: {
        label: STATUS_OPTIONS[0].title
      }
    }],
    urlKey: 'filters'
  });

  const filters = {
    dateStart: filterManager.getValue(FilterKeys.DateRangeStart) || moment(start).format('YYYY-MM-DD'),
    dateEnd: filterManager.getValue(FilterKeys.DateRangeEnd) || moment(end).format('YYYY-MM-DD'),
    type: filterManager.getValues(FilterKeys.Type)
  };

  const [timeSelect, setTimeSelect] = useState(() => {
    const selectedOption = timePeriodOptions.filter((op) => op.value.includes(`${filters.dateStart}|${filters.dateEnd}`));

    if (selectedOption.length) {
      return selectedOption[0].value;
    }

    return timePeriodOptions[0].value;
  });

  const flaggedActivityQuery = usePaginatedQuery(
    QueryKeys.FlaggedActivity.listing({
      uid,
      filters
    }), ({ pageParam = { limit: 10, offset: 0 } }) =>
      Data.getFlaggedActivityTimeline({
        userUid: uid,
        offset: pageParam.offset,
        limit: pageParam.limit,
        filters
      }), {
      enabled: !!uid,
      refetchOnMount: true,
      select: (data) => {
        return {
          userInfo: data.data[0] && data.data[0].userInfo,
          count: data.data[0] && data.data[0].count,
          pages: data.pages
        };
      },
      listKeyName: 'rows'
    });

  const flaggedActivityData = flaggedActivityQuery.data;

  const [timelineItems, userDetails] = useMemo(() => {
    let userDetails = null;
    if (flaggedActivityData.userInfo) {
      userDetails = {
        ...flaggedActivityData.userInfo
      };
    }

    const flaggedActivityItems = (flaggedActivityData.pages || []).map((activities) => {
      return {
        renderLabel: () => (
          <div className="w-24 flex-none flex flex-col items-end whitespace-nowrap">
            <Typography>
              {moment(new Date(activities[0].eventTrackedAt)).format('MMM DD, YYYY')}
            </Typography>
            <Typography>
              {moment(new Date(activities[0].eventTrackedAt)).format('hh:mm a')}
            </Typography>
          </div>
        ),
        renderValue: () => (
          <div className='gap-4 w-full'>
            {
              activities.length > 1
                ? (
                  activities.map((activity) => (
                    (
                      <div className='relative'>
                        <TimelineItem activity={activity} userDetails={userDetails} onFaceClick={onFaceClick} />

                        <div className="absolute right-full top-1/3 mr-1 h-1/3 rounded-bl-3xl border-l-2 border-b-2 border-gray-300" style={{ width: 'calc(9.25% / 2)' }} />
                      </div>
                    )
                  ))
                )
                : (
                  <TimelineItem activity={activities[0]} userDetails={userDetails} onFaceClick={onFaceClick} />
                )
            }
          </div>
        )
      };
    });

    return [flaggedActivityItems, userDetails];
  }, [flaggedActivityData]);

  const handleTimeSelectDropdown = (e) => {
    const { value } = e[0];
    if (!value.includes('|')) {
      setTimeSelect(value);
      return;
    }

    filterManager.onFilterApply({
      key: FilterKeys.DateRangeStart,
      value: moment(value.split('|')[1]).format('YYYY-MM-DD')
    });

    filterManager.onFilterApply({
      key: FilterKeys.DateRangeEnd,
      value: moment(value.split('|')[2]).format('YYYY-MM-DD')
    });

    setTimeSelect(value);
  };

  const onFaceClick = (faceCaptureDetails) => {
    setFaceCaptureModalOpen(true);
    setFaceCapturedDetails(faceCaptureDetails);
  };

  const onFaceCaptureModalClose = () => {
    setFaceCaptureModalOpen(false);
    setFaceCapturedDetails({});
  };

  const onUnban = useCallback((userUids) => {
    const loadingId = toast.loading('Unbanning users...');

    unbanLearnerMutation.mutate({ userUids }, {
      onSuccess: () => {
        toast.success('User successfully unbanned.');

        flaggedActivityQuery.refetch();
      },
      onError: () => {
        toast.error('Failed to unban user.');
      },
      onSettled: () => {
        toast.dismiss(loadingId);
      }
    });
  }, [unbanLearnerMutation, flaggedActivityQuery]);

  const onBan = useCallback((userUid) => {
    return ({ periodInDays, banIndefinitely, internalNote, noteForLearner }) => {
      const loadingId = toast.loading('Banning users...');

      banLearnerMutation.mutate({ userUid, periodInDays, banIndefinitely, internalNote, noteForLearner }, {
        onSuccess: () => {
          toast.success('User successfully banned.');

          flaggedActivityQuery.refetch();
        },
        onError: () => {
          toast.error('Failed to ban user.');
        },
        onSettled: () => {
          toast.dismiss(loadingId);
        }
      });
    };
  }, [banLearnerMutation, flaggedActivityQuery]);

  const onActionClick = useCallback((actionName) => {
    const learnerName = `${userDetails.firstName} ${userDetails.lastName}`;

    switch (actionName) {
    case UserActions.BanUser: {
      confirmAlert({
        customUI: ({ onClose }) => (
          <BanUserModalContent
            userDisplayName={learnerName}
            onClose={onClose}
            onSuccess={onBan(uid)}
          />
        )
      });

      break;
    }

    case UserActions.UnbanUser: {
      confirmAlert({
        customUI: ({ onClose }) => (
          <UnbanUserModalContent
            userDisplayName={learnerName}
            onClose={onClose}
            onSuccess={() => onUnban([uid])}
          />
        )
      });

      break;
    }

    default:
      break;
    }
  }, [uid, userDetails, onBan, onUnban]);

  return (
    <Box>
      <HeaderSubPanel title="Activity Timeline" links={breadcrumbLinks} sx={{ mb: 3 }} />
      <div className='nucleos-core'>
        <LoadingAndErrorHandler
          isError={flaggedActivityQuery.isError}
          isSuccess={flaggedActivityQuery.isSuccess}
        >
          <div className='flex justify-between items-center mb-3'>
            <div>
              <Typography variant='h4'>
              Activity Timeline
              </Typography>
            </div>

            <div>
              <div className='flex gap-x-2'>
                <div className={`items-center ${timeSelect ? 'hidden' : ''}`}>
                  <DateRangePicker
                    maxDate={new Date()}
                    disableHelperText
                    testId="custom-date-range"
                    className="w-48"
                    placeholder="Select date range"
                    fullWidth
                    onChange={({ startDate, endDate }) => {
                      filterManager.onFilterApply({
                        key: FilterKeys.DateRangeStart,
                        value: moment(startDate).format('YYYY-MM-DD')
                      });

                      filterManager.onFilterApply({
                        key: FilterKeys.DateRangeEnd,
                        value: moment(endDate).format('YYYY-MM-DD')
                      });
                    }}
                    value={{
                      startDate: moment(filters.dateStart).toDate(),
                      endDate: moment(filters.dateEnd).toDate()
                    }}
                    DropdownContainerProps={{
                      direction: 'left'
                    }}
                  />
                </div>

                <div>
                  <MultiSelectDropDown
                    dropdownId="flagged-activity-timeline-time-period-select"
                    disableHelperText
                    disableMultiSelect
                    onChange={(e) => {
                      handleTimeSelectDropdown(e);
                    }}
                    className="w-52"
                    value={timePeriodOptions.filter(op => op.value === timeSelect)}
                    label="Select Date"
                    options={timePeriodOptions}
                  />
                </div>

                <div>
                  <MultiSelectDropDown
                    dropdownId="flagged-activity-timeline-event-type-select"
                    testId="event-type"
                    disableHelperText
                    disableMultiSelect
                    onChange={(selected) => {
                      filterManager.onBulkFilterApply(
                        FilterKeys.Type,
                        selected.map((v) => ({
                          value: v.value,
                          meta: { label: v.title }
                        }))
                      );
                    }}
                    value={filterManager.getValues(FilterKeys.Type, { transformValue })}
                    label="Type"
                    className="w-40"
                    options={STATUS_OPTIONS}
                  />
                </div>

                {
                  userDetails &&
                  userPermissions.canBanUnbanLearner()
                    ? (
                      <div>
                        {
                          userDetails.isBanned
                            ? (
                              <Button
                                color='primary'
                                variant='contained'
                                style={{ backgroundColor: '#4C741F' }}
                                onClick={() => onActionClick(UserActions.UnbanUser)}
                              >
                                Unban Learner
                              </Button>
                            )
                            : (
                              <Button
                                variant='contained'
                                color="error"
                                onClick={() => onActionClick(UserActions.BanUser)}
                              >
                                Ban Learner
                              </Button>
                            )
                        }
                      </div>
                    )
                    : null
                }
              </div>
            </div>
          </div>

          <Divider style={{ height: 1 }} />

          <LoadingAndErrorHandler
            isLoading={flaggedActivityQuery.isPlaceholderData || flaggedActivityQuery.isLoading}
            isSuccess={flaggedActivityQuery.isSuccess}
          >
            {
              timelineItems.length
                ? (
                  <div className='mt-6 overflow-x-auto'>
                    <ScrollBottomLoading
                      dataCount={timelineItems.length}
                      isFetching={flaggedActivityQuery.isFetching}
                      fetchNextPage={flaggedActivityQuery.fetchNextPage}
                      hasMore={flaggedActivityQuery.hasNextPage}
                    >
                      <TimeLine items={timelineItems} />
                    </ScrollBottomLoading>
                  </div>
                )
                : <GenericNoDataFound />
            }
          </LoadingAndErrorHandler>
        </LoadingAndErrorHandler>

        <FaceCaptureModal
          image={faceCaptureDetails.imageURL}
          open={faceCaptureModalOpen}
          onClose={onFaceCaptureModalClose}
        />
      </div>
    </Box>
  );
};

export default observer(FlaggedActivityTimeline);
