import api from './Api';

import Authentication from '../Stores/Authentication';
import { ROLES } from '../Lib/CONSTANTS';
class DataApi extends api {
  constructor () {
    super();
    // TODO: Real endpoints
    this.coursesURL = '/course';
    this.usageHistoryURL = '/usage/getActivity';
    this.courseDetailURL = '/course';
    this.getGoalsURL = '/goal/user';
    this.editGoalURL = '/goal';
    this.usageChartDataURL = '/usage/getTotals';
    this.userURL = '/user';
    this.userAdminURL = '/admin/users';
    this.externalUserURL = '/externaluser';
    this.groupsURL = '/group';
    this.searchURL = '/usage/search';
    this.searchAPI = '/search';
    this.messagelogAPI = '/messagelog';
    this.chartdataAPI = '/usage/chartdata';
    this.appDetailURL = '/application';
    this.enrollmentURL = '/enrollment';
    this.licenseURL = '/license';
    this.adminUser = '/admin';
    this.approve = '/approve';
    this.forms = '/forms';
    this.formsAdmin = '/forms/admin';
    this.intouchMessenger = '/intouch/messenger';
    this.searchContactUrl = `${this.intouchMessenger}/search/contacts`;
    this.addContactMessengerUrl = `${this.intouchMessenger}/add/contacts`;
    this.acceptConsentMessengerUrl = `${this.intouchMessenger}/accept/consent`;
    this.initialConsentURL = '/user/initialConsentStatus';
    this.vantageURL = '/vantage';
  }

  // SIMPLE DATA
  // SEE COMMENTS IN DATASTORE.JS FOR HOW THIS CONFIG-DRIVEN SECTION WORKS
  addAppUser (uid, UserUid, add) {
    return this.putRequest(`${this.appDetailURL}/${uid}`, { UserUid, add });
  }

  addAppGroup (uid, GroupUid, add) {
    return this.putRequest(`${this.appDetailURL}/${uid}`, { GroupUid, add });
  }

  getLastActivity (uid) {
    return this.getRequest(`/usage/lastActivity/${uid}`);
  }

  setGranular (uid, granular) {
    return this.putRequest(`${this.appDetailURL}/${uid}`, { granular });
  }

  getSimpleData (simpleDataUrl) {
    return this.getRequest(simpleDataUrl);
  }

  getRawActivity (uid, from, to, orderBy, order, page, limit, apps) {
    return this.getRequest(`/usage/rawUsage/${uid}/?${from ? `from=${from}` : ''}${to ? `&to=${to}` : ''}${orderBy ? `&orderBy=${orderBy}` : ''}${order ? `&order=${order}` : ''}${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}${apps ? `&applications=${apps}` : ''}`);
  }

  // USER ACTIVITY
  getActivity (params) {
    return this.getRequest(this.usageHistoryURL, params);
  }

  getUsageChartData (params) {
    return this.getRequest(this.usageChartDataURL, params);
  }

  // APP DETAIL
  getAppDetail ({ id }) {
    return this.getRequest(`${this.appDetailURL}/${id}`);
  }

  getAppAvailability (id) {
    return this.getRequest(`${this.appDetailURL}/${id}/availability`);
  }

  putAppActive (id, active) {
    return this.putRequest(`${this.appDetailURL}/${id}?active=${String(!!active)}`);
  }

  getAppActive () {
    return this.getRequest(`${this.appDetailURL}?active=true&user=true`);
  }

  getApplications () {
    return this.getRequest(`${this.appDetailURL}`);
  }

  updateApplications (appId, data) {
    return this.putRequest(`${this.appDetailURL}/${appId}/?active=true`, data);
  }

  updateApplicationFacilityAvailability (appId, facilityId, data) {
    return this.postRequest(`${this.appDetailURL}/${appId}/facility/${facilityId}`, data);
  }

  removeApplicationFacilityAvailability (appId, facilityId) {
    return this.deleteRequest(`${this.appDetailURL}/${appId}/facility/${facilityId}`);
  }

  getApplicationFacilityAvailability (appId) {
    return this.getRequest(`${this.appDetailURL}/${appId}/facility`);
  }

  updateApplicationPodAvailability (appId, podId, data) {
    return this.postRequest(`${this.appDetailURL}/${appId}/pod/${podId}`, data);
  }

  removeApplicationPodAvailability (appId, podId) {
    return this.deleteRequest(`${this.appDetailURL}/${appId}/pod/${podId}`);
  }

  getApplicationPodAvailability (appId) {
    return this.getRequest(`${this.appDetailURL}/${appId}/pod`);
  }

  getCourses (ApplicationUid, hide) {
    return this.getRequest(`${this.coursesURL}?ApplicationUid=${ApplicationUid}&hide=${hide}`);
  }

  getCourseDetail ({ id }) {
    return this.getRequest(`${this.coursesURL}/${id}`, { modules: true });
  }

  updateCourse (id, data) {
    return this.putRequest(`${this.courseDetailURL}/${id}`, data);
  }

  // GOALS
  getGoals (uid) {
    return this.getRequest(`${this.getGoalsURL}/${uid}`);
  }

  deleteGoal (existingGoalId) {
    return this.deleteRequest(this.editGoalURL + '/' + existingGoalId);
  }

  setGoal (description, name, application, minutes, isWeekly, uid, existingGoalId) {
    const data = { description, name, uid, application, minutes, isWeekly };
    if (existingGoalId) {
      return this.putRequest(this.editGoalURL + '/' + existingGoalId, data);
    } else {
      return this.postRequest(this.editGoalURL, data);
    }
  }

  getUserTitles () {
    return this.getRequest('/user/userTitle');
  }

  generateUsername ({ firstName, lastName }) {
    return this.postRequest(`${this.userURL}/generate-username`, { firstName, lastName });
  }

  addUser (firstName, lastName, username, password, email, role, FacilityIds, grade, configRole, userTitleUid, showFirstName, dob) {
    return this.postRequest(`${this.userURL}`, {
      firstName,
      lastName,
      username,
      password,
      emailAddress: email,
      role,
      FacilityIds,
      configRoleId: configRole,
      userTitleUid,
      showFirstName,
      dob
    });
  }

  updateUser (uid, data, isUserEditFormRequest) {
    const search = new URLSearchParams();
    if (isUserEditFormRequest) {
      search.append('isUserEditFormRequest', isUserEditFormRequest);
    }

    return this.putRequest(`${this.userURL}/${uid}?${search.toString()}`, data);
  }

  updateUserMessengerStatus = (uid, status, externalUser = false) => {
    if (externalUser) {
      return this.putRequest(`${this.adminUser}/external/users/${uid}/messengerStatus`, { status });
    }
    return this.putRequest(`${this.userURL}/${uid}/messengerStatus`, { status });
  }

  getMessengerStatus = () => {
    const baseUrl = this.userURL;
    return this.getRequest(`${baseUrl}/messengerStatus`);
  }

  getSelf () {
    return this.getRequest(this.userURL);
  }

  getUser (uid, progress, externals) {
    let params = '';
    if (progress) {
      params = '?progress=true';
    }
    if (externals) {
      params += (params ? '&' : '?') + 'externals=true';
    }
    return this.getRequest(`${this.userURL}/${uid}${params}`);
  }

  getUsers ({ query, page, orderBy = '', order = '', roleType, filters, body, limit }) {
    const searchQuery = new URLSearchParams();

    searchQuery.append('q', query);
    searchQuery.append('page', page);
    searchQuery.append('order', order);
    searchQuery.append('orderBy', orderBy);
    if (limit) { searchQuery.append('limit', limit); }
    if (roleType) { searchQuery.append('roleType', roleType); }
    // filters = { active, email, fullName, username, startDateLogin, endDateLogin }
    if (filters) { searchQuery.append('filters', JSON.stringify(filters)); }

    return this.postRequest(`${this.userAdminURL}/get/?${searchQuery.toString()}`, body);
  }

  getExternalUsers (query, page, orderBy, order) {
    return this.getRequest(`${this.externalUserURL}?q=${query}&page=${page}&orderBy=${orderBy}&order=${order}`);
  }

  getGroups (groupsWithUsers = false, search = '', userCount = false, filters = '', page = 1, limit = 10, orderBy = '', order = '') {
    const searchParams = new URLSearchParams();
    if (groupsWithUsers) { searchParams.append('groupsWithUsers', true); }
    if (search) { searchParams.append('search', search); }
    if (page) { searchParams.append('page', page); }
    if (order) { searchParams.append('order', order); }
    if (orderBy) { searchParams.append('orderBy', orderBy); }
    if (userCount) { searchParams.append('userCount', true); }
    if (filters) { searchParams.append('filters', JSON.stringify(filters)); }
    if (limit) { searchParams.append('limit', limit); }
    return this.getRequest(`/group?${searchParams.toString()}`);
  }

  getGroupMembers ({ groupId, search, filters, page, limit, offset, orderBy, order }) {
    const searchParams = new URLSearchParams();

    if (search) { searchParams.append('search', search); }
    if (filters) { searchParams.append('filters', JSON.stringify(filters)); }
    if (order) { searchParams.append('order', order); }
    if (orderBy) { searchParams.append('orderBy', orderBy); }
    if (page) { searchParams.append('page', page); }
    if (limit) { searchParams.append('limit', limit); }
    if (offset) { searchParams.append('offset', offset); }

    return this.getRequest(`/group/${groupId}/member?${searchParams.toString()}`);
  }

  getEligibleGroupMembers ({ groupId, search, filters, page, limit, offset, orderBy, order }) {
    const searchParams = new URLSearchParams();

    if (search) { searchParams.append('q', search); }
    if (filters) { searchParams.append('filters', JSON.stringify(filters)); }
    if (order) { searchParams.append('order', order); }
    if (orderBy) { searchParams.append('orderBy', orderBy); }
    if (page) { searchParams.append('page', page); }
    if (limit) { searchParams.append('limit', limit); }
    if (offset) { searchParams.append('offset', offset); }

    return this.getRequest(`/group/${groupId}/eligible-user?${searchParams.toString()}`);
  }

  getGroup (id) {
    return this.getRequest(`/group/${id}`);
  }

  createGroup (data) {
    return this.postRequest('/group', data);
  }

  updateGroup (id, data) {
    return this.putRequest(`/group/${id}`, data);
  }

  addUserToGroup (id, userIds) {
    console.log('🚀 ~ file: Data.js:198 ~ DataApi ~ addUserToGroup ~ id, userIds:', id, userIds);
    return this.putRequest(`/group/${id}/addUsers`, { userIds });
  }

  removeUserFromGroup (id, userIds) {
    return this.putRequest(`/group/${id}/removeUsers`, { userIds });
  }

  deleteUser (uid) {
    return this.deleteRequest(`${this.userURL}/${uid}`);
  }

  // OLD END POINT
  bulkDeactivateUsers (uids, internalNote) {
    return this.putRequest(`${this.userAdminURL}/bulk/deactivate`, { uids, internalNote });
  }

  // bulkDeactivateUsers (uids) {
  //   return this.putUserManagementRequest(`/users/deactivate`, { uids })
  // }

  // OLD END POINT
  bulkReactivateUsers (uids) {
    return this.putRequest(`${this.userAdminURL}/bulk/reactivate`, { uids });
  }

  // bulkReactivateUsers (uids) {
  //   return this.putUserManagementRequest(`/users/reactivate`, { uids })
  // }

  temporaryActivateUser (userUids) {
    return this.postRequest(`${this.userAdminURL}/overrideActive`, { userUids });
  }

  bulkResetUsersPassword (uids) {
    return this.putRequest(`${this.userAdminURL}/bulk/resetPassword`, { uids });
  }

  updateGrievanceLimit ({ userUid, restrictGrievances, grievanceLimit }) {
    return this.postRequest(`${this.userAdminURL}/setGrievanceLimit`, { userUid, grievanceLimit, restrictGrievances });
  }

  deleteGroup (id) {
    return this.deleteRequest(`/group/${id}`);
  }

  searchUser (searchString, inactive) {
    return this.getRequest(`${this.searchURL}?user=${searchString}${inactive ? '&inactive=true' : ''}`);
  }

  searchFormsAdminUser (searchString, inactive, includeAppAdmins, facility, category, gid, cid, type) {
    return this.getRequest(`${this.formsAdmin}/search/admins?user=${searchString}${facility ? '&f=' + facility : ''}${category ? '&c=' + category : ''}${inactive ? '&inactive=true' : ''}${includeAppAdmins ? '&includeAppAdmins=true' : ''}${gid ? '&gid=' + gid : ''}${cid ? '&cid=' + cid : ''}${type ? `&type=${type}` : ''}`);
  }

  searchFormsLearnerUser (searchString, inactive, includeAppAdmins, facility, category, page, limit) {
    return this.getRequest(`${this.formsAdmin}/search/learners?user=${searchString}${facility ? '&f=' + facility : ''}${category ? '&c=' + category : ''}${inactive ? '&inactive=true' : ''}${includeAppAdmins ? '&includeAppAdmins=true' : ''}${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`);
  }

  getLearnerUsers (learnerSearch, facilities = [], pods = [], users = []) {
    const search = new URLSearchParams();
    if (facilities.length) { search.set('facilityIds', JSON.stringify(facilities)); }
    if (pods.length) { search.set('pods', JSON.stringify(pods)); }
    if (users.length) { search.set('users', JSON.stringify(users)); }
    if (learnerSearch) { search.set('q', learnerSearch); }
    return this.getRequest(`${this.formsAdmin}/learners?${search.toString()}`);
  }

  searchGroup (searchString) {
    return this.getRequest(`${this.searchAPI}/group/${searchString}`);
  }

  // addUserToGroup (uid, GroupUid) {
  //   return this.putRequest(`${this.groupsURL}/user/${GroupUid}`, { uid })
  // }

  deleteUsersFromGroup (users, GroupUid) {
    let url = `${this.groupsURL}/user/${GroupUid}`;
    const data = {};
    if (users.length > 1) {
      url = url + '?bulk=true';
      data.users = users;
    } else {
      data.uid = users[0];
    }
    return this.deleteRequest(url, data);
  }

  getPasswordReset (uid) {
    return this.getRequest(`${this.userAdminURL}/reset/${uid}`);
  }

  getPassword (uid) {
    return this.getRequest(`/password/${uid}`);
  }

  getLearningRecords (uid, offset, limit) {
    // return this.getRequest(`${this.coursesURL}s/${uid}`)
    return this.getRequest(`/usage/progress/${uid}${offset ? `?offset=${offset}` : ''}${limit ? `&limit=${limit}` : ''}`);
  }

  getUserLearningProgress (uid, offset, limit) {
    return this.getRequest(`/user/${uid}/courses${offset ? `?offset=${offset}` : ''}${limit ? `&limit=${limit}` : ''}`);
  }

  getCourseLessons ({ uid, courseId, sortBy, sortOrder }) {
    return this.getRequest(`/user/${uid}/course/${courseId}/lessons?orderBy=${sortBy}&order=${sortOrder}`);
  }

  getMessagelogs () {
    return this.getRequest(this.messagelogAPI);
  }

  getMessageLogCounts () {
    return this.getRequest(this.messagelogAPI + '/totals');
  }

  getChartData (uid) {
    const url = uid ? `${this.chartdataAPI}?user=${uid}` : this.chartdataAPI;
    return this.getRequest(url);
  }

  getRoles (forUserListing) {
    const searchParams = new URLSearchParams();
    if (typeof forUserListing === 'boolean') { searchParams.append('forUserListing', forUserListing); }
    const url = '/role?' + searchParams.toString();
    return this.getRequest(url);
  }

  searchGsuite (name) {
    const url = `/gsuite/search/${name}`;
    return this.getRequest(url);
  }

  groupApplicationTotals (groupId) {
    const url = `/reports/group?type=all_application_totals_day${groupId && groupId !== 'ALL' ? `&GroupId=${groupId}` : ''} `;
    return this.getRequest(url);
  }

  getGroupLearningRecordReportData ({ type, groupId, apps, learnersWithCourses, from, to, timePeriod, page, limit, orderBy, order, maxCount }) {
    const search = new URLSearchParams();
    search.set('type', type);
    if (groupId) { search.set('GroupId', groupId !== 'ALL' ? groupId : ''); }
    if (apps && apps.length) { search.set('applications', apps.join(',')); }
    if (learnersWithCourses) { search.set('learnersWithCourses', learnersWithCourses); }
    if (from) { search.set('from', from); }
    if (to) { search.set('to', to); }
    if (timePeriod) { search.set('timePeriod', timePeriod); }
    if (limit) { search.set('limit', limit); }
    if (page) { search.set('page', page); }
    if (orderBy) { search.set('orderBy', orderBy); }
    if (order) { search.set('order', order); }
    if (maxCount) { search.set('maxCount', maxCount); }
    const url = `/reports/group?${search.toString()}`;
    return this.getRequest(url);
  }

  getGroupLearningRecordAppCourses ({
    groupId,
    appId,
    from,
    to,
    order,
    orderBy,
    limit = 10,
    page,
    /*
     * Status can be STARTED or COMPLETED
     */
    status
  }) {
    const search = new URLSearchParams();
    if (from) { search.set('from', from); }
    if (to) { search.set('to', to); }
    if (limit) { search.set('limit', limit); }
    if (page) { search.set('page', page); }
    if (status) { search.set('status', status); }
    if (orderBy) { search.set('orderBy', orderBy); }
    if (order) { search.set('order', order); }
    const url = `/reports/group/${groupId}/application/${appId}/courses?${search.toString()}`;
    return this.getRequest(url);
  }

  getGroupLearningRecordUserCourses ({
    userId,
    apps,
    from,
    to,
    order,
    orderBy,
    limit,
    page,
    /*
     * Status can be STARTED or COMPLETED
     */
    status
  }) {
    const search = new URLSearchParams();
    if (from) { search.set('from', from); }
    if (to) { search.set('to', to); }
    if (apps && apps.length) { search.set('applications', apps.join(',')); }
    if (limit) { search.set('limit', limit); }
    if (page) { search.set('page', page); }
    if (status) { search.set('status', status); }
    if (orderBy) { search.set('orderBy', orderBy); }
    if (order) { search.set('order', order); }
    const url = `/reports/group/userStatusReport/${userId}?${search.toString()}`;
    return this.getRequest(url);
  }

  usersInGroupTotals (GroupId) {
    const url = `/reports/group?type=all_user_totals_in_group${GroupId && GroupId !== 'ALL' ? `&GroupId=${GroupId}` : ''}`;
    return this.getRequest(url);
  }

  groupCoursesAggregation (GroupId) {
    const url = `/reports/group?type=all_courses_count_in_group${GroupId && GroupId !== 'ALL' ? `&GroupId=${GroupId}` : ''}`;
    return this.getRequest(url);
  }

  sendFeedback (rating, message, url) {
    return this.postRequest('/feedback', { rating, message, url });
  }

  getUserUsageData (uid, from, to, timePeriod) {
    const url = `/reports/user?type=userPerDay&values=${uid}&${from ? `from=${from}` : ''}${to ? `&to=${to}` : ''}${timePeriod ? `&timePeriod=${timePeriod}` : ''}`;
    return this.getRequest(url);
  }

  getInstitution () {
    return this.getRequest('/site');
  }

  getInstitutions (uid) {
    return this.getRequest(`${this.userURL}/${uid}/institutions`);
  }

  getLaunchURL (ApplicationUid) {
    return this.getRequest(`/launch/url/${ApplicationUid}`);
  }

  // Enrollment
  getEnrollments (CourseId, UserUid, ApplicationUid, enrolled) {
    return this.getRequest(`${this.enrollmentURL}?CourseId=${CourseId}&UserUid=${UserUid}&ApplicationUid=${ApplicationUid}&enrolled=${enrolled}`);
  }

  getLicenses (ApplicationUid) {
    return this.getRequest(`/license?ApplicationUid=${ApplicationUid}`);
  }

  addLicense (ApplicationUid, UserUid, key, secret, description) {
    return this.postRequest('/license', { ApplicationUid, UserUid, key, secret, description });
  }

  updateLicense (id, UserUid) {
    return this.putRequest(`/license/${id}`, { UserUid });
  }

  acciEnroll ({ courseguid, firstname, lastname, inmateno, appUid }) {
    return this.postRequest('/micro/acci', { courseguid, firstname, lastname, inmateno, appUid });
  }

  acciCourses = async () => {
    return this.getRequest('/micro/acci/courses');
  }

  getNotifications = async ({ page = 1, category, isRead, searchTerm = '' } = {}) => {
    const qsPayload = {
      userId: Authentication.uid,
      page,
      searchTerm
    };

    if (category) {
      qsPayload.category = JSON.stringify(Array.isArray(category) ? category : [category]);
    }

    if (typeof isRead === 'boolean') {
      qsPayload.isRead = isRead;
    }

    const qs = new URLSearchParams(qsPayload).toString();

    return this.getRequest(`/notification?${qs}`);
  }

  markNotificationsAsRead = async ({ notificationIds, all = false, category, searchTerm = '' }) => {
    let payload = { ids: notificationIds };

    if (all) {
      payload = {
        selectAll: true
      };
    }

    const qsPayload = {};

    if (searchTerm) {
      qsPayload.searchTerm = searchTerm;
    }

    if (category) {
      qsPayload.category = JSON.stringify(Array.isArray(category) ? category : [category]);
    }

    const qs = new URLSearchParams(qsPayload).toString();

    if (qs) {
      payload = {};
    }

    return this.putRequest(`/notification/isRead?${qs}`, payload);
  }

  dismissNotification = async id => {
    return this.putRequest(`/notification/${id}`);
  }

  getRecentMessageSessions = async (search = '') => {
    const isLearnerUser = Authentication.fullUserRole === ROLES.STUDENT;
    return this.getRequest(
      `/message/recents?isLearnerUser=${isLearnerUser}${search ? ('&search=' + search) : ''
      }`
    );
  }

  getUnApprovedUser = async (page) => {
    return this.getRequest(`${this.adminUser}/external/users/unapproved/?page=${page}`);
  }

  updateUserApprovalStatus = async ({ approved, id }) => {
    return this.putRequest(`${this.adminUser}/external/users/approval`, { approved, id });
  }

  getMessageHistory = async (id, offset, limit) => {
    return this.getRequest(`/message/history/${id}?offset=${offset}&limit=${limit}`);
  }

  acceptConsent = async () => {
    return this.postRequest(`${this.acceptConsentMessengerUrl}?externalUser=${false}&userId=${Authentication.uid}`);
  }

  getInitialConsentStatus () {
    return this.getRequest(this.initialConsentURL);
  }

  setInitialConsentStatus () {
    return this.postRequest(this.initialConsentURL);
  }

  getApprovedUser = async () => {
    return this.getRequest(`${this.adminUser}/external/users/approved`);
  }

  createApprovedMessages = async (data) => {
    return this.postRequest(`${this.approve}`, data);
  }

  updateUserMessagingApprovalStatus = async (status, id) => {
    return this.putRequest(`${this.approve}/${id}/status`, { status });
  }

  updateUserMessagingResponseStatus = async (canUserRespond, id) => {
    return this.putRequest(`${this.intouchMessenger}/${id}/responseStatus`, { canUserRespond });
  }

  deActivateApprovedMessage = async (id) => {
    return this.deleteRequest(`${this.intouchMessenger}/${id}`);
  }

  searchContact = async (data, isIntouchUser = false) => {
    return this.postRequest(`${this.searchContactUrl}?isIntouchUser=${isIntouchUser}`, data);
  }

  addContactMessenger = async (data) => {
    return this.postRequest(`${this.addContactMessengerUrl}`, data);
  }

  getMessageAlertKeywords = async (page) => {
    return this.getRequest(`${this.adminUser}/messageAlerts/keywords/?page=${page}`);
  }

  addMessageAlertKeyword = async (data) => {
    return this.postRequest(`${this.adminUser}/messageAlerts/keywords`, data);
  }

  deleteMessageAlertKeyword = async (id) => {
    return this.deleteRequest(`${this.adminUser}/messageAlerts/keywords/${id}`);
  }

  getMessagesForAlertKeyword = async (keyword, page) => {
    return this.getRequest(`${this.adminUser}/messageAlerts/keywords/${keyword}/messages/?page=${page}`);
  }

  getMessageAlertsCount = async () => {
    return this.getRequest(`${this.adminUser}/messageAlerts/count`);
  }

  getAllMessages = async (query, page, orderBy, order, filters) => {
    return this.getRequest(`${this.adminUser}/messages/?q=${query}&page=${page}&orderBy=${orderBy}&order=${order}&filters=${JSON.stringify(filters)}`);
  }

  markAlertsAsViewed = async (keyword, page) => {
    return this.getRequest(`${this.adminUser}/messageAlerts/keywords/${keyword}/messages/markViewed?page=${page}`);
  }

  getExternalUser = async (uid, attachedUsers) => {
    let params = '';
    if (attachedUsers) {
      params += (params ? '&' : '?') + 'attachedUsers=true';
    }
    return this.getRequest(`${this.adminUser}/external/users/${uid}${params}`);
  }

  getMessages = async (uid, page) => {
    const url = `users/${uid}/messages`;
    return this.getRequest(`${this.adminUser}/${url}?page=${page}`);
  }

  getMessage = async (id) => {
    return this.getRequest(`${this.adminUser}/messages/${id}`);
  }

  getAllGrievances = async (page, orderBy = 'REQUEST_NUM', order = 'DESC') => {
    return this.getRequest(`${this.forms}/grievances?page=${page}&orderBy=${orderBy}&order=${order}`);
  }

  checkGrievanceWeeklylimitStatus = async () => {
    return this.getRequest(`${this.forms}/grievances/weeklylimitStatus`);
  }

  getAllAdminGrievances = async (query, page, orderBy, order, filters, data, limit = 10) => {
    return this.postRequest(`${this.formsAdmin}/grievances/?q=${query}&page=${page}&orderBy=${orderBy}&order=${order}&limit=${limit}&filters=${JSON.stringify(filters)}`, data);
  }

  getAdminAssignedGrievances = async (query, page, orderBy, order, filters) => {
    return this.getRequest(`${this.formsAdmin}/grievances/assigned?q=${query}&page=${page}&orderBy=${orderBy}&order=${order}&filters=${JSON.stringify(filters)}`);
  }

  getGrievance = async (id) => {
    return this.getRequest(`${this.forms}/grievances/${id}`);
  }

  getAdminGrievance = async (id) => {
    return this.getRequest(`${this.formsAdmin}/grievances/${id}`);
  }

  getAllGrievanceCategories = async ({ requestType, roleType } = {}) => {
    const searchQuery = new URLSearchParams();

    if (requestType) { searchQuery.append('requestType', requestType); }
    if (roleType) { searchQuery.append('roleType', roleType); }

    return this.getRequest(`${this.forms}/grievances/categories?${searchQuery.toString()}`);
  }

  getAllLearnerGrievanceCategories = async ({ requestType, permissionType } = {}) => {
    const searchQuery = new URLSearchParams();

    if (requestType) { searchQuery.append('requestType', requestType); }
    if (permissionType) { searchQuery.append('permissionType', permissionType); }

    return this.getRequest(`${this.forms}/grievances/learner/categories?${searchQuery.toString()}`);
  }

  addGrievance = async (data) => {
    return this.postRequest(`${this.forms}/grievances`, data);
  }

  updateGrievanceResponseStatus = async (grievanceId, responseId, status) => {
    return this.putRequest(`${this.forms}/grievances/${grievanceId}/response/${responseId}`, { status });
  }

  getAllGrievancesCount = async () => {
    return this.getRequest(`${this.formsAdmin}/grievances/count`);
  }

  getAssignedGrievancesCount = async () => {
    return this.getRequest(`${this.formsAdmin}/grievances/assigned/count`);
  }

  getAllFormRoles = async (category, facility) => {
    let params = '';
    if (facility) {
      params += `?f=${facility}`;
    }
    if (category) {
      params += (params ? '&' : '?') + `c=${category}`;
    }
    return this.getRequest(`${this.formsAdmin}/roles${params}`);
  }

  getFormRolesWithRespondPermissions = async (category, facility, gid) => {
    let params = '';
    if (facility) {
      params += `&f=${facility}`;
    }
    if (category) {
      params += `&c=${category}`;
    }
    return this.getRequest(`${this.formsAdmin}/roles/canRespond?gid=${gid}${params}`);
  }

  getNewFormRoles = async (gid, cid, type) => {
    return this.getRequest(`${this.formsAdmin}/assignedRoles/grievance/${gid}${cid ? `?cid=${cid}` : ''}${type ? `&type=${type}` : ''}`);
  }

  getAllFacilities = async () => {
    return this.getRequest(`${this.formsAdmin}/facilities`);
  }

  assignFormRoleToUser = async (role, user) => {
    const data = {
      role,
      user
    };
    return this.putRequest(`${this.formsAdmin}/roles`, data);
  }

  unAssignFormRoleFromUser = async (role, user) => {
    const data = {
      role,
      user,
      remove: true
    };
    return this.putRequest(`${this.formsAdmin}/roles`, data);
  }

  changeGrievanceType = async (id) => {
    return this.putRequest(`${this.formsAdmin}/grievance/${id}/requestType`);
  }

  deleteGrievanceAttachment = async (grievanceId, attachmentId) => {
    const data = { attachmentId };

    return this.deleteRequest(`${this.formsAdmin}/grievances/${grievanceId}/attachment`, data);
  }

  submitGrievanceResponse = async (id, response, attachments, onProgress) => {
    const data = { response, attachments };

    return this.XHRUploadRequest(`${this.formsAdmin}/grievances/${id}/response`, data, onProgress);
  }

  getFormResponseTemplates = async (cId) => {
    return this.getRequest(`${this.formsAdmin}/templates?cid=${cId}`);
  }

  getAllFormTemplateCategories = async () => {
    return this.getRequest(`${this.formsAdmin}/templates/categories`);
  }

  addFormResponseTemplate = async (template) => {
    return this.postRequest(`${this.formsAdmin}/templates`, template);
  }

  updateFormResponseTemplate = async (template) => {
    return this.putRequest(`${this.formsAdmin}/templates/${template.id}`, template);
  }

  assignGrievance = async (gid, categoryId, assignToType, assignId, isLegacyRole) => {
    const data = {
      categoryId,
      assignToType,
      assignId,
      isLegacyRole
    };
    return this.postRequest(`${this.formsAdmin}/grievances/${gid}/assign`, data);
  }

  duplicateAndAssignGrievance = async (gid, categoryId, assignToType, assignId, isLegacyRole) => {
    const data = {
      categoryId,
      assignToType,
      assignId,
      isLegacyRole
    };
    return this.postRequest(`${this.formsAdmin}/grievances/${gid}/duplicateAndAssign`, data);
  }

  savePreferences = async ({ preferencesKey, preferences }) => {
    return this.postRequest(`${this.userURL}/preferences`, {
      preferences: {
        [preferencesKey]: preferences
      }
    });
  }

  saveRolesPreferences = async (preferences) => {
    return this.postRequest(`${this.userURL}/preferences`, {
      preferences: {
        roles: preferences
      }
    });
  }

  getPreferences = async () => {
    return this.getRequest(`${this.userURL}/preferences`);
  }

  getGrievanceReport = async (filters) => {
    const { seperateByFacility, startDate, endDate } = filters;
    return this.getRequest(`${this.forms}/grievances/report?separateDataByFacility=${seperateByFacility}&filters=${JSON.stringify({ startDate, endDate })}`);
  }

  downloadGrievanceReport = async (filters) => {
    const { seperateByFacility, startDate, endDate, responseType } = filters;
    return this.downloadGetRequest(`${this.forms}/grievances/report?separateDataByFacility=${seperateByFacility}&responseType=${responseType}&filters=${JSON.stringify({ startDate, endDate })}`);
  }

  verifyEmail = async (data) => {
    return this.postRequest('/verifyEmail', data);
  }

  resendVerificationEmail = async (data) => {
    return this.putRequest(`${this.userURL}/resendVerificationEmail`, data);
  }

  createRole = async (data) => {
    return this.postRequest(`${this.forms}/configRoles`, data);
  }

  getUpdatedRoles = async ({ sortBy, sortOrder, page, limit, roleName = '', isLearner, forUserListing, forGroupListing }) => {
    const searchParams = new URLSearchParams();
    if (sortBy) { searchParams.append('sortBy', sortBy); }
    if (sortOrder) { searchParams.append('sortOrder', sortOrder); }
    if (typeof page === 'number') { searchParams.append('page', page); }
    if (limit) { searchParams.append('limit', limit); }
    if (roleName) { searchParams.append('roleName', roleName); }
    if (typeof isLearner === 'boolean') { searchParams.append('isLearner', isLearner); }
    if (typeof forUserListing === 'boolean') { searchParams.append('forUserListing', forUserListing); }
    if (typeof forGroupListing === 'boolean') { searchParams.append('forGroupListing', forGroupListing); }
    return this.getRequest(`${this.forms}/configRoles?${searchParams.toString()}`);
  }

  getUpdatedAllRoles = async () => {
    return this.getRequest(`${this.forms}/configRoles`);
  }

  getUpdatedRole = async (roleId) => {
    return this.getRequest(`${this.forms}/configRoles/${roleId}`);
  }

  updateRole = async (data, roleId) => {
    return this.putRequest(`${this.forms}/configRoles/${roleId}`, data);
  }

  getPods = async (facilityIds = []) => {
    const params = new URLSearchParams();
    if (facilityIds.length) { params.set('facilityIds', JSON.stringify(facilityIds)); }
    return this.getRequest(`${this.formsAdmin}/pods?${params.toString()}`);
  }

  getApplicationPods = async () => {
    return this.getRequest(`${this.formsAdmin}/pods/supported`);
  }

  getGrievanceRole = async (userId) => {
    return this.getRequest(`/getUserPayload/${userId}`);
  }

  getConfigurations = async () => {
    return this.getRequest('/admin/users/configuration');
  }

  getExtensionConfigurations = async () => {
    return this.getRequest('/extension/configuration');
  }

  deleteRole = async (id) => {
    return this.deleteRequest(`${this.forms}/configRoles/${id}`);
  }

  setGrievanceSettings = ({
    disableGrievanceForAllLearners,
    facilityIds = [],
    pods = [],
    userIds = []
  }) => {
    return this.putRequest(`${this.adminUser}/users/setGrievanceFeatureFlag`, {
      disableGrievanceForAllLearners,
      facilityIds,
      pods,
      userIds
    });
  };

  getGrievanceSettings = () => {
    return this.getRequest(`${this.formsAdmin}/grievances/settings`);
  };

  getTutorials = async (tenantId) => {
    return this.getRequest(`/tutorials?tenantId=${tenantId}`);
  }

  getTutorial = async (tutorialId, tenantId) => {
    return this.getRequest(`/tutorials/${tutorialId}?tenantId=${tenantId}`);
  }

  getLiveUsers = async ({ page = 1, limit, facilityIds = [], users = [], pods = [], getLive = false }) => {
    const urlSearch = new URLSearchParams();
    urlSearch.append('page', page - 1);
    urlSearch.append('facilityIds', JSON.stringify(facilityIds));
    urlSearch.append('pods', JSON.stringify(pods));
    urlSearch.append('users', JSON.stringify(users));
    urlSearch.append('getLive', getLive);
    if (limit) { urlSearch.append('limit', limit); }
    return this.getRequest(`${this.formsAdmin}/getLiveUsers?${urlSearch.toString()}`);
  }

  getApplicationACLUsers = async ({ applicationUid, query, orderBy = '', order = '', page, limit = 10, offset }) => {
    const searchQuery = new URLSearchParams();

    if (limit) { searchQuery.append('limit', limit); }
    if (page) { searchQuery.append('page', page); }
    if (offset) { searchQuery.append('offset', offset); }
    if (orderBy) { searchQuery.append('orderBy', orderBy); }
    if (order) { searchQuery.append('order', order); }
    if (query) { searchQuery.append('q', JSON.stringify(query)); }

    return this.getRequest(`/application/${applicationUid}/acl-user?${searchQuery.toString()}`);
  }

  getApplicationACLGroups = async ({ applicationUid, query, orderBy = '', order = '', page, limit = 10, offset }) => {
    const searchQuery = new URLSearchParams();

    if (limit) { searchQuery.append('limit', limit); }
    if (page) { searchQuery.append('page', page); }
    if (offset) { searchQuery.append('offset', offset); }
    if (orderBy) { searchQuery.append('orderBy', orderBy); }
    if (order) { searchQuery.append('order', order); }
    if (query) { searchQuery.append('q', JSON.stringify(query)); }

    return this.getRequest(`/application/${applicationUid}/acl-group?${searchQuery.toString()}`);
  }

  getApplicationUsers = async (applicationUid, query, orderBy, order, page, limit = 10) => {
    return this.getRequest(`${this.adminUser}/applicationUsers?applicationUid=${applicationUid}&q=${query || ''}${orderBy ? `&orderBy=${orderBy}` : ''}${order ? `&order=${order}` : ''}${typeof page === 'number' ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`);
  }

  createApplicationUser = async (data) => {
    return this.postRequest(`${this.adminUser}/applicationUsers`, data);
  }

  updateApplicationUser = async (data) => {
    return this.putRequest(`${this.adminUser}/applicationUsers`, data);
  }

  getMissingApplicationUsers = async (applicationUid, query) => {
    return this.getRequest(`${this.adminUser}/applicationUsers/users?applicationUid=${applicationUid}&q=${query || ''}`);
  }

  getActivityLookupMetaData (userId, data) {
    return this.getRequest(`${this.userURL}/userTimeLine/${userId}`, data);
  }

  getActivityLookupChunk (userId, data) {
    return this.getRequest(`${this.userURL}/recordings/${userId}`, data);
  }

  getLearnerActiveSessions = async ({ limit, page, offset, orderBy, order, filters, data }) => {
    const searchQuery = new URLSearchParams();

    if (limit) { searchQuery.append('limit', limit); }
    if (page) { searchQuery.append('page', page); }
    if (offset) { searchQuery.append('offset', offset); }
    if (orderBy) { searchQuery.append('orderBy', orderBy); }
    if (order) { searchQuery.append('order', order); }
    if (filters) { searchQuery.append('q', JSON.stringify(filters)); }

    return this.postRequest(`${this.adminUser}/users/device/sessions?${searchQuery.toString()}`, data);
  }

  getLearnerSessionAuditLogs = async (data) => {
    return this.getRequest(`${this.adminUser}/users/device/logs`, data);
  }

  unlockDevice = async (data) => {
    return this.postRequest(`${this.adminUser}/users/device/unlock`, data);
  }

  getUserApplications = async (userId, appsWithLogin, query, orderBy, order, page, limit = 10) => {
    return this.getRequest(`${this.userURL}/applications/${userId}?${appsWithLogin ? '&appsWithLogin=true' : ''}&q=${query || ''}${orderBy ? `&orderBy=${orderBy}` : ''}${order ? `&order=${order}` : ''}${typeof page === 'number' ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`);
  }

  getUserAuditLogs = async (userId, page, limit = 10) => {
    return this.getRequest(`${this.userURL}/userLogs/${userId}?${typeof page === 'number' ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}`);
  }

  getFaceCaptureLogsTimeline = async (data) => {
    return this.getRequest('/userBiometric/faceCapture/timeLine', data);
  }

  getFaceCaptureLogsDetails = async (data) => {
    return this.getRequest('/userBiometric/faceCapture/details', data);
  }

  getApplicationAuditLogs = async (data) => {
    return this.getRequest(`${this.adminUser}/application/audit/logs/${data.applicationUid}`, data);
  }

  getApplicationAuditLogsEventTypes = async () => {
    return this.getRequest(`${this.adminUser}/application/audit/types`);
  }

  vantageSSOLogin = async (uid) => {
    return this.postRequest(`${this.vantageURL}/ssoLogin`, { userId: uid });
  }

  getFlaggedEvents ({ page, offset, orderBy = '', order = '', filters, limit }) {
    const searchQuery = new URLSearchParams();

    if (limit) { searchQuery.append('limit', limit); }
    if (page) { searchQuery.append('page', page); }
    if (offset) { searchQuery.append('offset', offset); }
    if (orderBy) { searchQuery.append('orderBy', orderBy); }
    if (order) { searchQuery.append('order', order); }
    if (filters) { searchQuery.append('q', JSON.stringify(filters)); }

    return this.getRequest(`${this.userAdminURL}/violation?${searchQuery.toString()}`);
  }

  getFlaggedActivityTimeline ({ userUid, page, offset, filters, limit }) {
    const searchQuery = new URLSearchParams();

    if (limit) { searchQuery.append('limit', limit); }
    if (page) { searchQuery.append('page', page); }
    if (offset) { searchQuery.append('offset', offset); }
    if (filters) { searchQuery.append('q', JSON.stringify(filters)); }

    return this.getRequest(`${this.userAdminURL}/violation/${userUid}?${searchQuery.toString()}`);
  }

  banLearner ({ userUid, periodInDays, banIndefinitely, internalNote, noteForLearner }) {
    const payload = {
      userUid,
      periodInDays,
      banIndefinitely,
      internalNote,
      noteForLearner
    };

    if (banIndefinitely) {
      delete payload.periodInDays;
    }

    return this.postRequest(`${this.userAdminURL}/violation/ban`, payload);
  }

  unbanLearner ({ userUids }) {
    const payload = {
      userUids
    };

    return this.putRequest(`${this.userAdminURL}/violation/unban`, payload);
  }

  setDefaultPassword (uids) {
    const payload = { userUids: uids };

    return this.putRequest(`${this.userAdminURL}/resetPassword`, payload);
  }

  getClientTheme () {
    return this.getRequest('/settings/client?module=WHITE_LABELING');
  }

  getDeviceDetails ({ serialNumber }) {
    return this.postRequest('/device-detail', { serialNumber });
  }

  getPendingForApprovalLicenses = async (data) => {
    return this.getRequest(`${this.adminUser}/licenses`, data);
  }

  getApprovedLicenses = async (data) => {
    return this.getRequest(`${this.adminUser}/licenses`, data);
  }

  getRequestLicenseCourses = async (data) => {
    return this.getRequest('/license/course', data);
  }

  requestLicense = async (data) => {
    return this.postRequest('/license', data);
  }

  approveLicense = async (data) => {
    return this.putRequest(`${this.adminUser}/licenses/${data.licenseId}/approve`, data);
  }

  rejectLicense = async (data) => {
    return this.putRequest(`${this.adminUser}/licenses/${data.licenseId}/reject`, data);
  }

  assignCourseLicense = async (data) => {
    return this.postRequest(`${this.adminUser}/licenses`, data);
  }

  updateCourseLicense = async (data) => {
    return this.putRequest(`${this.adminUser}/licenses/${data.licenseId}`, data);
  }

  deleteCourseLicense = async (data) => {
    return this.putRequest(`${this.adminUser}/licenses/${data.licenseId}/delete`, data);
  }
}

export default new DataApi();
